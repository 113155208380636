import React from "react";
import { YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";
  
import { groups, citySite } from "../constants";
import TitleOfBlock from "./TitleOfBlock";
  
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} mr-0 h-5 w-5 transition-transform mt-2 sm:mt-0 sm:mr-4` }
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

function CurrDay(numday) {
  const currDate = new Date();
  const isCurrentDay = numday === currDate.getDay();

  return isCurrentDay ? "font-bold text-aa-blue-lite" : "";
}

export default function ScheduleWithMap() {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const [openSelfMap, setOpenSelfMap] = React.useState(0);
  const handleOpenSelfMap = (value) => setOpenSelfMap(openSelfMap === value ? 0 : value);
 
  return (
    <>
      <div className="blocks" id='schedule'>
        <div className='w-full px-1 sm:px-4'>
          <TitleOfBlock title="Расписание групп" />
          <div className="flex  justify-center px-1 sm:px-6 md:px-18 lg:px-20 xl:px-48">
          <div className="px-4">
          <p className="pb-10">
            Собрания Анонимных Алкоголиков - это возможность прийти и услышать тех, кто остается трезвым с помощью 12 шагов Анонимных Алкоголиков.
          </p>
          </div>
          </div>
          <YMaps
            query={{
              ns: "use-load-option",
              load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
            }}
          >
            <div className='px-1 sm:px-6 md:px-18 lg:px-20 xl:px-48'>
              <div 
                // className="xl:w-1/2 lg:w-2/3 md:w-full lg:pr-4"
              >
                {groups.map((group, index) => (
                  <div className="items-center">
                  <Accordion 
                    key={group.name} 
                    className="shadow-md my-2 rounded-2xl border border-blue-gray-900 overflow-hidden" 
                    open={open === index + 1} 
                    icon={<Icon id={index + 1} open={open} />}>
                    <AccordionHeader 
                      className={`flex flex-col py-2 sm:py-4 sm:flex-row justify-between border-b-0 transition-colors ${
                        open === index + 1 ? "": " bg-gray-50 hover:bg-white"}`} 
                      onClick={() => handleOpen(index + 1)}
                    >
                      <div 
                        className="px-1 sm:px-4 flex flex-col md:flex-row"
                      >
                        <div 
                          className="z-1 flex-none w-40"
                        >
                          <h3 className=" text-left pb-3 text-aa-blue-lite">
                            «{group.name}»
                          </h3>
                        </div>
                        <div className="">
                          <table className=" rounded-2xl w-full table-fixed text-center text-[3vw] sm:text-sm transition ease-in-out bg-blue-100 ">
                            <thead className="font-light">
                              <tr>
                                <th scope="col" className={`${CurrDay(1)} font-medium border-r border-blue-50 py-1`}>ПН</th>
                                <th scope="col" className={`${CurrDay(2)} font-medium border-r border-blue-50 py-1`}>ВТ</th>
                                <th scope="col" className={`${CurrDay(3)} font-medium border-r border-blue-50 py-1`}>СР</th>
                                <th scope="col" className={`${CurrDay(4)} font-medium border-r border-blue-50 py-1`}>ЧТ</th>
                                <th scope="col" className={`${CurrDay(5)} font-medium border-r border-blue-50 py-1`}>ПТ</th>
                                <th scope="col" className={`${CurrDay(6)} font-medium border-r border-blue-50 py-1`}>СБ</th>
                                <th scope="col" className={`${CurrDay(0)} font-medium py-1`}>ВС</th>
                              </tr>
                            </thead>
                            <tbody className="font-light text-slate-900">
                              {group.schedule.map((day, index) => (
                                <tr key={group.name} className="">
                                  <td className={`${CurrDay(1)} border-r border-blue-50 whitespace-pre py-1`}>{day.mon}</td>
                                  <td className={`${CurrDay(2)} border-r border-blue-50 whitespace-pre py-1`}>{day.tue}</td>
                                  <td className={`${CurrDay(3)} border-r border-blue-50 whitespace-pre py-1`}>{day.wed}</td>
                                  <td className={`${CurrDay(4)} border-r border-blue-50 whitespace-pre py-1`}>{day.thu}</td>
                                  <td className={`${CurrDay(5)} border-r border-blue-50 whitespace-pre py-1`}>{day.fri}</td>
                                  <td className={`${CurrDay(6)} border-r border-blue-50 whitespace-pre py-1`}>{day.sat}</td>
                                  <td className={`${CurrDay(0)} whitespace-pre py-1`}>{day.sun}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="w-auto px-4 text-base lg:text-lg">
                      <p><strong>Метро:</strong> {group.metro}</p>
                      <Accordion open={openSelfMap === 1} key='map-for-element-of-group'>
                        <AccordionHeader 
                          className=' w-auto py-0 text-base lg:text-lg font-light border-none' 
                          onClick={() => handleOpenSelfMap(1)}
                        >
                          <p><strong>Адрес:</strong> <span className="underline lg:hover:decoration-2 decoration-blue-200">
                              {group.adres}
                            </span>
                          </p>
                        </AccordionHeader>
                        <AccordionBody>
                          <div className="">
                            <Map
                              defaultState={{
                                center: group.location,
                                zoom: 15,
                              }}
                              width='100%'
                              height='300px'
                            >
                              <Placemark 
                                key={group.name + "self"} 
                                geometry={group.location}
                                properties={{
                                  balloonContentBody:
                                    group.adres + " " + group.descr + " " + group.warning
                                }}
                              />
                            </Map>
                          </div>
                        </AccordionBody>
                      </Accordion>

                      <p>
                        <strong>Тел. <a className="text-aa-blue-lite text-lg underline lg:no-underline decoration-blue-200" href={`tel:${group.tel}`}> 
                          {group.tel}
                        </a></strong>
                      </p>
                      <p><strong>{group.descr ? 'Примечания:' : ''}</strong> {group.descr}</p>
                      <p><strong>{group.warning ? 'Важно:' : ''}</strong><span className="text-red-600"> {group.warning}</span></p>
                    </AccordionBody>
                  </Accordion>
                  </div>
                ))}
              </div>

              
            </div>
          </YMaps>
          <div className="flex  justify-center px-1 sm:px-6 md:px-18 lg:px-20 xl:px-48">
          <div className="px-4">
          <p className=" text-base">
            <span className="font-light text-[3vw] sm:text-sm bg-blue-100 px-2 pb-1 pt-0.5 rounded-md">(о)</span> - Открытые собрания: посещать может любой человек, не только страдающий от алкоголизма.
          </p>
          </div>
          </div>
          {citySite.map((citySite, name, index) => (
          <div className='flex  justify-center'>
            <button className='text-aa-blue-lite text-[4vw] sm:text-xl py-4 px-8 mt-10 rounded-xl border border-gray-200 hover:bg-white bg-gray-50 shadow-md'>
              <a 
                href={citySite.scheduleUrl}
                rel="noreferrer"
                target="_blank"
                alt={citySite.name}
              >
                Группы в других районах Северо-Запада
              </a>
            </button>
          </div>
          ))}
        </div>
      </div>
    </>
  );
}
