import React from 'react'

const TitleOfBlock = (props) => {
    return (
        <div className='mb-10 flex justify-center'>
            <h2 className='sm:text-4xl pb-3 text-3xl border-b-4 border-aa-blue-lite'>
                {props.title}
            </h2>
        </div>
    )
}

export default TitleOfBlock