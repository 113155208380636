import React from "react";
import TitleOfBlock from "./TitleOfBlock";

export default function Video() {
  return (
    <>
      <div className="blocks" id="video">
        <TitleOfBlock title="Опыт АА"/>
        <div className="pb-20 lg:px-32 xl:px-48">
          <div className="relative pb-[56.25%] overflow-hidden">
            <iframe className="absolute top-0 left-0 w-[100%] h-[100%] rounded-xl"
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/QRUEQCYsvgU?si=foDMQiX5psFklrR7" 
              title="Видео плеер"
              // frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}