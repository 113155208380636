import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero '>                    
            <div className='content blocks'>
                <div className='glass-panel md:w-min border backdrop-blur-md border-gray-400 px-5 md:px-16 py-10 rounded-3xl'>
                    <h1 className="font-AATitleFont text-[10vw] text-5xl sm:text-6xl">Анонимные Алкоголики</h1>
                    <p className='text-2xl text-[6vw] italic sm:text-4xl'>Фрунзенского района</p>
                    <div className=''>
                        <button className='text-[4vw] sm:text-xl py-4 px-8 mt-10 rounded-xl bg-aa-blue hover:bg-blue-800 hover:shadow-xl hover:shadow-blue-400/20'>
                            <a href='#schedule' alt='Кнопка Расписание групп'>
                                Расписание групп
                            </a>
                        </button>
                    </div>
                    
                </div>
                <div className='z-20'>
                    
                </div>
            </div>
        </div>
    )
}

export default Hero
