import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import Footer from './components/Footer'
import ScheduleWithMap from './components/ScheduleWithMap'
import Questions from './components/Questions'
import Video from './components/Video'
import MapFooter from './components/MapFooter'

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <ScheduleWithMap />
      <Questions />
      <Video />
      <MapFooter />
      <Footer />
    </div>
  );
}

export default App;
