import React, {useState} from 'react'
import { navLinks } from "../constants";
import logoAA from './images/logoAA.svg'

import './Footer.css'

const Footer = () => {
    const [, setClick] = useState(false)

    // const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    const [, setActive] = useState("Home");

    return (
        <div className='footer bg-aa-blue'>
            <div className='container grid grid-flow-row md:grid-flow-col  gap-4'>

                <div className='order-1 md:-order-last flex justify-center md:justify-normal'>
                    <a href='#hero' className='logo'>
                        <img src={logoAA} alt='logoAA' width={160} />
                    </a>
                </div>

                <div className='mb-2 grid grid-cols-1 content-center'>
                    <ul className="flex flex-wrap">
                        {navLinks.map((nav, index) => (
                            <li
                                key={nav.id}
                                className=''
                                onClick={() => setActive(nav.title)}
                            >
                                <a 
                                    href={`${nav.href ? nav.href: `#${nav.id}`}`} 
                                    target={`${nav.href ? "_blank": ""}`} 
                                    onClick={closeMenu}>{nav.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
            <footer className='mt-8 text-center'>
                <small>&copy; 2024 Анонимные Алкоголики Фрунзенского района.</small>
            </footer>
        </div>
    )
}

export default Footer