import React from 'react'
import { questionsAA, questionsAABottom } from "../constants";
import TitleOfBlock from './TitleOfBlock'

const Questions = () => {
    return (
        <div className="blocks" id="questions">
            <div className='px-1 sm:px-4 md:px-18 lg:px-32 xl:px-48'>
                <TitleOfBlock title="Вопросы новичка" />
                <div className=''>
                    <ol className="list-decimal list-outside pl-8 lg:pl-12">
                        {questionsAA.map((question, index) => (
                            <li key={question.id} className=''>
                                <h3 className='text-aa-blue-lite pb-2 font-medium text-md'>
                                    {question.question}
                                </h3>
                                <p className='pb-6 font-light text-base'>
                                    {question.ansver}
                                </p>
                            </li>
                        ))}
                    </ol>
                    {questionsAABottom.map((questbottom, index) => (
                        <div key={questbottom.id} className='px-3 pt-8 text-md'>
                            <h3 className='text-aa-blue-lite pb-2 font-medium'>
                                {questbottom.title}
                            </h3>
                            <p className='text-base pb-6'>
                                {questbottom.text}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Questions