import React from 'react'
import { YMaps, Map, Placemark, Polygon} from '@pbe/react-yandex-maps';
  
import { groups } from "../constants";
import TitleOfBlock from "./TitleOfBlock";


const MapFooter = () => {
  return (
    <div className=''>
          <TitleOfBlock title="Группы в нашем районе" />
          <YMaps
            query={{
              ns: "use-load-option",
              load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
            }}
          >
                    <Map
                      instanceRef={ref => { ref && ref.behaviors.disable(['scrollZoom', 'drag']); }}
                      defaultState={{
                        center: [59.869967, 30.390789],
                        zoom: 11,
                      }}
                      width='100%'
                      height='400px'
                    >
                      {groups.map((group, index) => (
                        <Placemark 
                          key={group.name} 
                          geometry={group.location}
                          properties={{
                            balloonContentBody: group.adres + " " + group.descr + " " + group.warning
                          }}
                        />
                        
                      ))}
                      <Polygon
                geometry={[
                  [[59.817269,30.383359],
                  [59.819863,30.404988],
                  [59.825222,30.431424],
                  [59.829543,30.439664],
                  [59.834037,30.475026],
                  [59.848204,30.456143],
                  [59.867028,30.425244],
                  [59.879628,30.407048],
                  [59.892688,30.391942],
                  [59.90752,30.370999],
                  [59.91252,30.363446],
                  [59.914072,30.362759],
                  [59.915968,30.34525],
                  [59.915451,30.3401],
                  [59.91321,30.333234],
                  [59.89158,30.346451],
                  [59.884038,30.348597],
                  [59.866138,30.360356],
                  [59.817269,30.383359]]
                ]}
                options={{
                  fill: true,
                  fillColor: "#00FF00",
                  fillOpacity: 0.1,
                  strokeColor: "#006B00",
                  opacity: 0.6,
                  strokeWidth: 4,
                  strokeStyle: "solid",
                }}
            />
                    </Map>
          </YMaps>
    </div>
  )
}

export default MapFooter