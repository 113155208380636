import React from 'react'
import './About.css'
import { about } from '../constants'
import TitleOfBlock from './TitleOfBlock'

const About = () => {
    return (
        <div className="blocks" id='about'>
            <div className='px-4 sm:px-12 md:px-18 lg:px-32 xl:px-48'>
                <TitleOfBlock title="О нас" />
                {about.map((about, index) => (
                    <span className='whitespace-pre-line text-md' key={about.id}>
                        <p>
                            {about.content}
                        </p>
                    </span>
                ))}
            </div>
        </div>
    )
}

export default About
