import React, { useState, useEffect } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import logoAA from './images/logoAA.svg'
import { navLinks } from "../constants";

import './Navbar.css'

const Navbar = () => {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    const [, setActive] = useState("Home");

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
  
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY;
  
        setVisible(
          (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 0.3) ||
          currentScrollPos < 0.1
        );
  
        setPrevScrollPos(currentScrollPos);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos, visible]);

    return (
        <div className={`z-20 header w-full fixed top-0 transition-all duration-500 ${visible ? 'translate-none' : '-translate-y-full'}`}>
            <nav className={`navbar`}>
                <div className=''>
                    <a href='#hero' className='logo'>
                        <img src={logoAA} alt='logoAA' />
                    </a>
                </div>
                
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{color: 'white'}} />)
                    : (<FaBars size={30} style={{color: '#ffffff'}} />)}
                </div>


                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    {navLinks.map((nav, index) => (
                        <li
                            key={nav.id}
                            className='nav-item'
                            onClick={() => setActive(nav.title)}
                        >
                            <a 
                                href={`${nav.href ? nav.href: `#${nav.id}`}`} 
                                rel={`${nav.href ? "noreferrer": ""}`}
                                target={`${nav.href ? "_blank": ""}`} 
                                onClick={closeMenu}>{nav.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )
}

export default Navbar
